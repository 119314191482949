/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const baseBg = "rgb(229, 229, 229)";
export const baseSubtleBg = "rgb(255, 255, 255)";
export const baseFg = "rgb(41, 40, 39)";
export const baseSubtleFg = "rgb(89, 89, 89)";
export const baseDivider = "rgb(89, 89, 89)";
export const baseBackdrop = "rgba(0, 0, 0, 0.1)";
export const baseNeutral50 = "rgb(250, 250, 250)";
export const baseNeutral100 = "rgb(238, 238, 238)";
export const baseNeutral200 = "rgb(207, 207, 207)";
export const baseNeutral300 = "rgb(176, 176, 176)";
export const baseNeutral400 = "rgb(148, 148, 148)";
export const baseNeutral500 = "rgb(132, 132, 132)";
export const baseNeutral600 = "rgb(117, 117, 117)";
export const baseNeutral700 = "rgb(86, 86, 86)";
export const baseNeutral800 = "rgb(59, 59, 59)";
export const baseNeutral900 = "rgb(41, 40, 39)";
export const baseNeutral00 = "rgb(255, 255, 255)";
export const baseInvertedBg = "rgb(41, 40, 39)";
export const baseInvertedSubtleBg = "rgb(84, 84, 255)";
export const baseInvertedFg = "rgb(255, 255, 255)";
export const baseInvertedSubtleFg = "rgb(153, 153, 153)";
export const baseInvertedDivider = "rgb(153, 153, 153)";
export const plussaltPrimaryBg = "rgb(36, 4, 61)";
export const plussaltSecondaryBg = "rgb(171, 181, 255)";
export const plussaltPrimaryFg = "rgb(255, 255, 255)";
export const plussaltPrimarySubtleFg = "rgb(171, 181, 255)";
export const plussaltSecondaryFg = "rgb(0, 0, 0)";
export const plussaltSecondarySubtleFg = "rgb(36, 4, 61)";
export const plussaltPrimaryHoverBg = "rgb(25, 3, 43)";
export const plussaltPrimaryHoverBorder = "rgb(196, 203, 255)";
export const plussaltSecondaryHoverBg = "rgb(196, 203, 255)";
export const plussaltSecondaryHoverBorder = "rgb(196, 203, 255)";
export const electoralPartiesAPrimaryBg = "rgb(224, 18, 32)";
export const electoralPartiesAPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesASecondaryBg = "rgb(250, 221, 224)";
export const electoralPartiesASecondaryFg = "rgb(169, 6, 23)";
export const electoralPartiesFrpPrimaryBg = "rgb(0, 79, 128)";
export const electoralPartiesFrpPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesFrpSecondaryBg = "rgb(230, 241, 252)";
export const electoralPartiesFrpSecondaryFg = "rgb(0, 108, 169)";
export const electoralPartiesHPrimaryBg = "rgb(0, 101, 241)";
export const electoralPartiesHPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesHSecondaryBg = "rgb(225, 247, 254)";
export const electoralPartiesHSecondaryFg = "rgb(0, 116, 155)";
export const electoralPartiesKrfPrimaryBg = "rgb(255, 193, 7)";
export const electoralPartiesKrfPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesKrfSecondaryBg = "rgb(255, 248, 229)";
export const electoralPartiesKrfSecondaryFg = "rgb(144, 103, 11)";
export const electoralPartiesMdgPrimaryBg = "rgb(78, 126, 0)";
export const electoralPartiesMdgPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesMdgSecondaryBg = "rgb(232, 243, 225)";
export const electoralPartiesMdgSecondaryFg = "rgb(81, 109, 7)";
export const electoralPartiesRodtPrimaryBg = "rgb(183, 28, 28)";
export const electoralPartiesRodtPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesRodtSecondaryBg = "rgb(250, 226, 221)";
export const electoralPartiesRodtSecondaryFg = "rgb(149, 45, 0)";
export const electoralPartiesSpPrimaryBg = "rgb(120, 190, 33)";
export const electoralPartiesSpPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesSpSecondaryBg = "rgb(246, 251, 231)";
export const electoralPartiesSpSecondaryFg = "rgb(93, 123, 16)";
export const electoralPartiesSvPrimaryBg = "rgb(235, 64, 64)";
export const electoralPartiesSvPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesSvSecondaryBg = "rgb(253, 234, 249)";
export const electoralPartiesSvSecondaryFg = "rgb(157, 15, 130)";
export const electoralPartiesVPrimaryBg = "rgb(0, 102, 102)";
export const electoralPartiesVPrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesVSecondaryBg = "rgb(230, 250, 244)";
export const electoralPartiesVSecondaryFg = "rgb(12, 114, 85)";
export const electoralPartiesOther1PrimaryBg = "rgb(97, 98, 103)";
export const electoralPartiesOther1PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther1SecondaryBg = "rgb(220, 220, 220)";
export const electoralPartiesOther1SecondaryFg = "rgb(92, 93, 98)";
export const electoralPartiesOther2PrimaryBg = "rgb(82, 97, 98)";
export const electoralPartiesOther2PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther2SecondaryBg = "rgb(225, 232, 229)";
export const electoralPartiesOther2SecondaryFg = "rgb(82, 97, 98)";
export const electoralPartiesOther3PrimaryBg = "rgb(82, 91, 109)";
export const electoralPartiesOther3PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther3SecondaryBg = "rgb(228, 230, 235)";
export const electoralPartiesOther3SecondaryFg = "rgb(82, 91, 109)";
export const electoralPartiesOther4PrimaryBg = "rgb(112, 116, 92)";
export const electoralPartiesOther4PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther4SecondaryBg = "rgb(234, 235, 228)";
export const electoralPartiesOther4SecondaryFg = "rgb(100, 102, 89)";
export const electoralPartiesOther5PrimaryBg = "rgb(98, 82, 82)";
export const electoralPartiesOther5PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther5SecondaryBg = "rgb(232, 225, 225)";
export const electoralPartiesOther5SecondaryFg = "rgb(98, 82, 82)";
export const electoralPartiesOther6PrimaryBg = "rgb(113, 106, 138)";
export const electoralPartiesOther6PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther6SecondaryBg = "rgb(228, 223, 228)";
export const electoralPartiesOther6SecondaryFg = "rgb(107, 94, 113)";
export const electoralPartiesOther7PrimaryBg = "rgb(108, 95, 59)";
export const electoralPartiesOther7PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther7SecondaryBg = "rgb(239, 239, 231)";
export const electoralPartiesOther7SecondaryFg = "rgb(108, 95, 59)";
export const electoralPartiesOther8PrimaryBg = "rgb(82, 96, 109)";
export const electoralPartiesOther8PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther8SecondaryBg = "rgb(228, 231, 235)";
export const electoralPartiesOther8SecondaryFg = "rgb(82, 96, 109)";
export const electoralPartiesOther9PrimaryBg = "rgb(89, 109, 129)";
export const electoralPartiesOther9PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther9SecondaryBg = "rgb(227, 237, 239)";
export const electoralPartiesOther9SecondaryFg = "rgb(91, 102, 111)";
export const electoralPartiesOther10PrimaryBg = "rgb(98, 98, 98)";
export const electoralPartiesOther10PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther10SecondaryBg = "rgb(225, 225, 225)";
export const electoralPartiesOther10SecondaryFg = "rgb(83, 83, 83)";
export const electoralPartiesOther11PrimaryBg = "rgb(109, 82, 93)";
export const electoralPartiesOther11PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther11SecondaryBg = "rgb(235, 228, 232)";
export const electoralPartiesOther11SecondaryFg = "rgb(109, 82, 93)";
export const electoralPartiesOther12PrimaryBg = "rgb(98, 93, 82)";
export const electoralPartiesOther12PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther12SecondaryBg = "rgb(232, 230, 225)";
export const electoralPartiesOther12SecondaryFg = "rgb(98, 93, 82)";
export const electoralPartiesOther13PrimaryBg = "rgb(103, 79, 58)";
export const electoralPartiesOther13PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther13SecondaryBg = "rgb(236, 225, 217)";
export const electoralPartiesOther13SecondaryFg = "rgb(103, 79, 58)";
export const electoralPartiesOther14PrimaryBg = "rgb(103, 98, 119)";
export const electoralPartiesOther14PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther14SecondaryBg = "rgb(233, 230, 236)";
export const electoralPartiesOther14SecondaryFg = "rgb(85, 86, 107)";
export const electoralPartiesOther15PrimaryBg = "rgb(115, 115, 115)";
export const electoralPartiesOther15PrimaryFg = "rgb(255, 255, 255)";
export const electoralPartiesOther15SecondaryBg = "rgb(231, 227, 227)";
export const electoralPartiesOther15SecondaryFg = "rgb(101, 93, 93)";
export const valgomatBg = "rgb(248, 248, 248)";
export const valgomatFg = "rgb(48, 65, 56)";
export const valgomatDivider = "rgb(233, 233, 233)";
export const valgomatBorder = "rgb(224, 224, 224)";
export const utilityPrimaryBg = "rgb(41, 40, 39)";
export const utilityPrimarySubtleBg = "rgb(50, 48, 48)";
export const utilityPrimaryHoverBg = "rgb(51, 51, 51)";
export const utilityPrimaryActiveBg = "rgb(41, 40, 39)";
export const utilityPrimaryDivider = "rgb(153, 153, 153)";
export const utilityPrimaryBorder = "rgb(255, 255, 255)";
export const utilityPrimaryHoverBorder = "rgb(204, 204, 204)";
export const utilityPrimaryFg = "rgb(255, 255, 255)";
export const utilityPrimarySubtleFg = "rgb(153, 153, 153)";
export const utilitySecondaryBg = "rgb(222, 222, 222)";
export const utilitySecondarySubtleBg = "rgb(243, 243, 243)";
export const utilitySecondaryHoverBg = "rgb(216, 216, 216)";
export const utilitySecondaryActiveBg = "rgb(204, 204, 204)";
export const utilitySecondaryDivider = "rgb(174, 174, 174)";
export const utilitySecondaryBorder = "rgb(141, 141, 141)";
export const utilitySecondaryHoverBorder = "rgb(128, 128, 128)";
export const utilitySecondaryFg = "rgb(41, 40, 39)";
export const utilitySecondarySubtleFg = "rgb(96, 96, 96)";
export const utilitySignalBg = "rgb(41, 40, 39)";
export const utilitySignalSubtleBg = "rgb(50, 48, 48)";
export const utilitySignalHoverBg = "rgb(51, 51, 51)";
export const utilitySignalActiveBg = "rgb(41, 40, 39)";
export const utilitySignalDivider = "rgb(153, 153, 153)";
export const utilitySignalBorder = "rgb(255, 255, 255)";
export const utilitySignalHoverBorder = "rgb(204, 204, 204)";
export const utilitySignalFg = "rgb(255, 255, 255)";
export const utilitySignalSubtleFg = "rgb(153, 153, 153)";
export const utilitySuccessBg = "rgb(185, 225, 185)";
export const utilitySuccessSubtleBg = "rgb(232, 247, 232)";
export const utilitySuccessHoverBg = "rgb(173, 224, 173)";
export const utilitySuccessActiveBg = "rgb(147, 214, 147)";
export const utilitySuccessDivider = "rgb(120, 195, 122)";
export const utilitySuccessBorder = "rgb(52, 148, 72)";
export const utilitySuccessHoverBorder = "rgb(42, 128, 60)";
export const utilitySuccessFg = "rgb(25, 51, 26)";
export const utilitySuccessSubtleFg = "rgb(31, 119, 49)";
export const utilityWarningBg = "rgb(242, 228, 202)";
export const utilityWarningSubtleBg = "rgb(252, 246, 235)";
export const utilityWarningHoverBg = "rgb(240, 223, 192)";
export const utilityWarningActiveBg = "rgb(232, 212, 176)";
export const utilityWarningDivider = "rgb(218, 187, 131)";
export const utilityWarningBorder = "rgb(202, 161, 85)";
export const utilityWarningHoverBorder = "rgb(220, 169, 75)";
export const utilityWarningFg = "rgb(65, 54, 32)";
export const utilityWarningSubtleFg = "rgb(202, 136, 7)";
export const utilityDangerBg = "rgb(237, 205, 205)";
export const utilityDangerSubtleBg = "rgb(251, 234, 234)";
export const utilityDangerHoverBg = "rgb(238, 200, 200)";
export const utilityDangerActiveBg = "rgb(237, 194, 194)";
export const utilityDangerDivider = "rgb(205, 120, 120)";
export const utilityDangerBorder = "rgb(197, 80, 80)";
export const utilityDangerHoverBorder = "rgb(172, 57, 57)";
export const utilityDangerFg = "rgb(52, 23, 23)";
export const utilityDangerSubtleFg = "rgb(173, 17, 17)";
export const utilityInteractionBg = "rgb(202, 222, 242)";
export const utilityInteractionSubtleBg = "rgb(235, 243, 252)";
export const utilityInteractionHoverBg = "rgb(192, 216, 240)";
export const utilityInteractionActiveBg = "rgb(176, 204, 232)";
export const utilityInteractionDivider = "rgb(131, 174, 218)";
export const utilityInteractionBorder = "rgb(49, 111, 209)";
export const utilityInteractionFocusOutline = "rgb(49, 111, 209)";
export const utilityInteractionHoverBorder = "rgb(45, 105, 200)";
export const utilityInteractionFg = "rgb(25, 44, 62)";
export const utilityInteractionSubtleFg = "rgb(9, 107, 202)";
export const buttonPrimaryBg = "rgb(41, 40, 39)";
export const buttonPrimaryHoverBg = "rgb(51, 51, 51)";
export const buttonPrimaryActiveBg = "rgb(41, 40, 39)";
export const buttonPrimaryFg = "rgb(255, 255, 255)";
export const buttonPrimaryBorder = "rgba(255, 255, 255, 0)";
export const buttonPrimaryHoverBorder = "rgba(204, 204, 204, 0)";
export const buttonSecondaryBg = "rgb(222, 222, 222)";
export const buttonSecondaryHoverBg = "rgb(216, 216, 216)";
export const buttonSecondaryActiveBg = "rgb(204, 204, 204)";
export const buttonSecondaryFg = "rgb(41, 40, 39)";
export const buttonSecondaryBorder = "rgba(141, 141, 141, 0)";
export const buttonSecondaryHoverBorder = "rgba(128, 128, 128, 0)";
export const buttonOutlinedBg = "rgb(255, 255, 255)";
export const buttonOutlinedHoverBg = "rgb(247, 247, 247)";
export const buttonOutlinedActiveBg = "rgb(227, 227, 227)";
export const buttonOutlinedBorder = "rgb(41, 40, 39)";
export const buttonOutlinedHoverBorder = "rgb(51, 51, 51)";
export const buttonOutlinedFg = "rgb(41, 40, 39)";
export const buttonSignalBg = "rgb(41, 40, 39)";
export const buttonSignalHoverBg = "rgb(51, 51, 51)";
export const buttonSignalActiveBg = "rgb(41, 40, 39)";
export const buttonSignalFg = "rgb(255, 255, 255)";
export const buttonSignalBorder = "rgba(255, 255, 255, 0)";
export const buttonSignalHoverBorder = "rgba(204, 204, 204, 0)";
export const buttonTertiaryBg = "rgb(18, 18, 18)";
export const buttonTertiaryHoverBg = "rgb(216, 216, 216)";
export const buttonTertiaryActiveBg = "rgb(204, 204, 204)";
export const buttonTertiaryFg = "rgb(41, 40, 39)";
export const buttonTertiaryBorder = "rgba(141, 141, 141, 0)";
export const buttonTertiaryHoverBorder = "rgba(128, 128, 128, 0)";
export const pillNonePulseBg = "rgb(238, 0, 0)";
export const pillNonePulseEndBg = "rgba(238, 0, 0, 0)";
export const pillNonePulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillNoneFg = "rgb(41, 40, 39)";
export const pillNoneBreakingFg = "rgb(238, 0, 0)";
export const pillNoneDivider = "rgb(191, 191, 191)";
export const pillNoneFilledPulseBg = "rgb(238, 0, 0)";
export const pillNoneFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillNoneFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillNoneFilledBg = "rgb(255, 255, 255)";
export const pillNoneFilledFg = "rgb(41, 40, 39)";
export const pillNoneFilledBreakingFg = "rgb(238, 0, 0)";
export const pillNoneFilledBreakingBg = "rgb(255, 255, 255)";
export const pillNoneFilledDivider = "rgb(191, 191, 191)";
export const pillBlackPulseBg = "rgb(238, 0, 0)";
export const pillBlackPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillBlackPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillBlackFg = "rgb(255, 255, 255)";
export const pillBlackBreakingFg = "rgb(255, 255, 255)";
export const pillBlackDivider = "rgb(191, 191, 191)";
export const pillBlackFilledPulseBg = "rgb(238, 0, 0)";
export const pillBlackFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillBlackFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillBlackFilledBg = "rgb(41, 40, 39)";
export const pillBlackFilledFg = "rgb(255, 255, 255)";
export const pillBlackFilledBreakingFg = "rgb(255, 255, 255)";
export const pillBlackFilledBreakingBg = "rgb(41, 40, 39)";
export const pillBlackFilledDivider = "rgb(191, 191, 191)";
export const pillHighlightPulseBg = "rgb(238, 0, 0)";
export const pillHighlightPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillHighlightPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillHighlightFg = "rgb(0, 0, 0)";
export const pillHighlightBreakingFg = "rgb(0, 0, 0)";
export const pillHighlightDivider = "rgba(0, 0, 0, 0.5)";
export const pillHighlightFilledPulseBg = "rgb(238, 0, 0)";
export const pillHighlightFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillHighlightFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillHighlightFilledBg = "rgb(255, 205, 41)";
export const pillHighlightFilledFg = "rgb(0, 0, 0)";
export const pillHighlightFilledBreakingFg = "rgb(0, 0, 0)";
export const pillHighlightFilledBreakingBg = "rgb(255, 205, 41)";
export const pillHighlightFilledDivider = "rgba(0, 0, 0, 0.5)";
export const pillCustomOnePulseBg = "rgb(238, 0, 0)";
export const pillCustomOnePulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomOnePulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomOneFg = "rgb(255, 255, 255)";
export const pillCustomOneBreakingFg = "rgb(255, 255, 255)";
export const pillCustomOneDivider = "rgba(255, 255, 255, 0.3)";
export const pillCustomOneFilledPulseBg = "rgb(238, 0, 0)";
export const pillCustomOneFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomOneFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomOneFilledBg = "rgb(0, 94, 115)";
export const pillCustomOneFilledFg = "rgb(255, 255, 255)";
export const pillCustomOneFilledBreakingFg = "rgb(255, 255, 255)";
export const pillCustomOneFilledBreakingBg = "rgb(0, 94, 115)";
export const pillCustomOneFilledDivider = "rgba(255, 255, 255, 0.3)";
export const pillCustomTwoPulseBg = "rgb(238, 0, 0)";
export const pillCustomTwoPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomTwoPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomTwoFg = "rgb(41, 40, 39)";
export const pillCustomTwoBreakingFg = "rgb(41, 40, 39)";
export const pillCustomTwoDivider = "rgba(71, 70, 70, 0.2)";
export const pillCustomTwoFilledPulseBg = "rgb(238, 0, 0)";
export const pillCustomTwoFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomTwoFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomTwoFilledBg = "rgb(254, 209, 252)";
export const pillCustomTwoFilledFg = "rgb(41, 40, 39)";
export const pillCustomTwoFilledBreakingFg = "rgb(41, 40, 39)";
export const pillCustomTwoFilledBreakingBg = "rgb(254, 209, 252)";
export const pillCustomTwoFilledDivider = "rgba(71, 70, 70, 0.2)";
export const pillSportPulseBg = "rgb(255, 255, 255)";
export const pillSportPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const pillSportPulseEndBg = "rgba(255, 255, 255, 0)";
export const pillSportFg = "rgb(255, 255, 255)";
export const pillSportBreakingFg = "rgb(255, 255, 255)";
export const pillSportDivider = "rgba(255, 255, 255, 0.3)";
export const pillSportFilledPulseBg = "rgb(255, 255, 255)";
export const pillSportFilledPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const pillSportFilledPulseEndBg = "rgba(255, 255, 255, 0)";
export const pillSportFilledBg = "rgb(1, 23, 43)";
export const pillSportFilledFg = "rgb(255, 255, 255)";
export const pillSportFilledBreakingFg = "rgb(255, 255, 255)";
export const pillSportFilledBreakingBg = "rgb(1, 23, 43)";
export const pillSportFilledDivider = "rgba(255, 255, 255, 0.3)";
export const pillCommercialPulseBg = "rgb(0, 0, 0)";
export const pillCommercialPulseStartBg = "rgba(0, 0, 0, 0.7)";
export const pillCommercialPulseEndBg = "rgba(0, 0, 0, 0)";
export const pillCommercialFg = "rgb(0, 0, 0)";
export const pillCommercialBreakingFg = "rgb(0, 0, 0)";
export const pillCommercialDivider = "rgba(0, 0, 0, 0.5)";
export const pillCommercialFilledPulseBg = "rgb(0, 0, 0)";
export const pillCommercialFilledPulseStartBg = "rgba(0, 0, 0, 0.7)";
export const pillCommercialFilledPulseEndBg = "rgba(0, 0, 0, 0)";
export const pillCommercialFilledBg = "rgb(244, 207, 62)";
export const pillCommercialFilledFg = "rgb(0, 0, 0)";
export const pillCommercialFilledBreakingFg = "rgb(0, 0, 0)";
export const pillCommercialFilledBreakingBg = "rgb(244, 207, 62)";
export const pillCommercialFilledDivider = "rgba(0, 0, 0, 0.5)";
export const pillBettingPulseBg = "rgb(255, 255, 255)";
export const pillBettingPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const pillBettingPulseEndBg = "rgba(255, 255, 255, 0)";
export const pillBettingFg = "rgb(255, 255, 255)";
export const pillBettingBreakingFg = "rgb(255, 255, 255)";
export const pillBettingDivider = "rgba(255, 255, 255, 0.3)";
export const pillBettingFilledPulseBg = "rgb(255, 255, 255)";
export const pillBettingFilledPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const pillBettingFilledPulseEndBg = "rgba(255, 255, 255, 0)";
export const pillBettingFilledBg = "rgb(1, 23, 43)";
export const pillBettingFilledFg = "rgb(255, 255, 255)";
export const pillBettingFilledBreakingFg = "rgb(255, 255, 255)";
export const pillBettingFilledBreakingBg = "rgb(1, 23, 43)";
export const pillBettingFilledDivider = "rgba(255, 255, 255, 0.3)";
export const pillShoppingPulseBg = "rgb(0, 0, 0)";
export const pillShoppingPulseStartBg = "rgba(0, 0, 0, 0.7)";
export const pillShoppingPulseEndBg = "rgba(0, 0, 0, 0)";
export const pillShoppingFg = "rgb(0, 0, 0)";
export const pillShoppingBreakingFg = "rgb(0, 0, 0)";
export const pillShoppingDivider = "rgba(0, 0, 0, 0.5)";
export const pillShoppingFilledPulseBg = "rgb(0, 0, 0)";
export const pillShoppingFilledPulseStartBg = "rgba(0, 0, 0, 0.7)";
export const pillShoppingFilledPulseEndBg = "rgba(0, 0, 0, 0)";
export const pillShoppingFilledBg = "rgb(255, 255, 255)";
export const pillShoppingFilledFg = "rgb(0, 0, 0)";
export const pillShoppingFilledBreakingFg = "rgb(0, 0, 0)";
export const pillShoppingFilledBreakingBg = "rgb(255, 255, 255)";
export const pillShoppingFilledDivider = "rgba(0, 0, 0, 0.5)";
export const pillOpinionFilledPulseBg = "rgb(255, 255, 255)";
export const pillOpinionFilledPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const pillOpinionFilledPulseEndBg = "rgba(255, 255, 255, 0)";
export const pillOpinionFilledBg = "rgb(30, 127, 110)";
export const pillOpinionFilledFg = "rgb(255, 255, 255)";
export const pillOpinionFilledBreakingFg = "rgb(255, 255, 255)";
export const pillOpinionFilledBreakingBg = "rgb(30, 127, 110)";
export const pillAltFilledBg = "rgb(255, 112, 51)";
export const pillAltFilledFg = "rgb(0, 0, 0)";
export const pillCustomThreePulseBg = "rgb(238, 0, 0)";
export const pillCustomThreePulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomThreePulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomThreeFg = "rgb(41, 40, 39)";
export const pillCustomThreeBreakingFg = "rgb(41, 40, 39)";
export const pillCustomThreeDivider = "rgba(71, 70, 70, 0.2)";
export const pillCustomThreeFilledPulseBg = "rgb(238, 0, 0)";
export const pillCustomThreeFilledPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const pillCustomThreeFilledPulseEndBg = "rgba(238, 0, 0, 0)";
export const pillCustomThreeFilledBg = "rgb(220, 216, 216)";
export const pillCustomThreeFilledFg = "rgb(41, 40, 39)";
export const pillCustomThreeFilledBreakingFg = "rgb(41, 40, 39)";
export const pillCustomThreeFilledBreakingBg = "rgb(220, 216, 216)";
export const pillCustomThreeFilledDivider = "rgba(71, 70, 70, 0.2)";
export const premiumIconBg = "rgb(41, 40, 39)";
export const premiumIconFg = "rgb(255, 255, 255)";
export const vizMapTempHigh = "rgb(196, 16, 18)";
export const vizMapTempMidHigh = "rgb(210, 105, 30)";
export const vizMapTempMid = "rgb(255, 204, 20)";
export const vizMapTempMidLow = "rgb(200, 237, 175)";
export const vizMapTempLow = "rgb(161, 201, 227)";
export const vizMapDangerHigh = "rgb(196, 16, 18)";
export const vizMapDangerMidHigh = "rgb(210, 105, 30)";
export const vizMapDangerMid = "rgb(255, 204, 20)";
export const vizMapDangerNone = "rgb(174, 173, 173)";
export const supportiveAltBg = "rgb(255, 112, 51)";
export const supportiveAltFg = "rgb(18, 18, 18)";
export const supportiveNonePulseBg = "rgb(238, 0, 0)";
export const supportiveNonePulseEndBg = "rgba(238, 0, 0, 0)";
export const supportiveNoneBg = "rgb(255, 255, 255)";
export const supportiveNoneFg = "rgb(41, 40, 39)";
export const supportiveNoneSubtleFg = "rgb(71, 70, 70)";
export const supportiveNoneDivider = "rgba(71, 70, 70, 0.2)";
export const supportiveNoneBreakingFg = "rgb(238, 0, 0)";
export const supportiveNoneBreakingBg = "rgb(255, 255, 255)";
export const supportiveNoneOpaqueFg = "rgba(0, 0, 0, 0.15)";
export const supportiveCustomOnePulseStartBg = "rgba(255, 255, 255, 0.7)";
export const supportiveCustomOnePulseEndBg = "rgba(255, 255, 255, 0)";
export const supportiveCustomOneBreakingBg = "rgb(0, 94, 115)";
export const supportiveCustomOneDivider = "rgba(255, 255, 255, 0.3)";
export const supportiveCustomOneBg = "rgb(0, 94, 115)";
export const supportiveCustomOneFg = "rgb(255, 255, 255)";
export const supportiveCustomOneSubtleFg = "rgb(255, 255, 255)";
export const supportiveCustomOneBreakingFg = "rgb(255, 255, 255)";
export const supportiveCustomTwoPulseStartBg = "rgba(255, 255, 255, 0.7)";
export const supportiveCustomTwoPulseEndBg = "rgba(255, 255, 255, 0)";
export const supportiveCustomTwoBreakingBg = "rgb(254, 209, 252)";
export const supportiveCustomTwoDivider = "rgba(71, 70, 70, 0.2)";
export const supportiveCustomTwoBg = "rgb(254, 209, 252)";
export const supportiveCustomTwoFg = "rgb(41, 40, 39)";
export const supportiveCustomTwoSubtleFg = "rgb(41, 40, 39)";
export const supportiveCustomTwoBreakingFg = "rgb(255, 255, 255)";
export const supportiveSportDivider = "rgba(255, 255, 255, 0.3)";
export const supportiveSportBg = "rgb(1, 23, 43)";
export const supportiveSportAccentBg = "rgb(0, 96, 176)";
export const supportiveSportGradient = "linear-gradient(180deg, #01172b00 0%, #01172b00 70%, #01172b 100%)";
export const supportiveSportFg = "rgb(255, 255, 255)";
export const supportiveSportSubtleFg = "rgb(255, 255, 255)";
export const supportiveBlackPulseStartBg = "rgba(238, 0, 0, 0.7)";
export const supportiveBlackPulseEndBg = "rgba(238, 0, 0, 0)";
export const supportiveBlackDivider = "rgb(191, 191, 191)";
export const supportiveBlackBg = "rgb(41, 40, 39)";
export const supportiveBlackFg = "rgb(255, 255, 255)";
export const supportiveBlackSubtleFg = "rgb(255, 255, 255)";
export const supportiveBlackBreakingFg = "rgb(238, 0, 0)";
export const supportiveBlackBreakingBg = "rgb(238, 0, 0)";
export const supportiveBlackOpaqueFg = "rgba(255, 255, 255, 0.13)";
export const supportiveHighlightBreakingFg = "rgb(238, 0, 0)";
export const supportiveHighlightBg = "rgb(255, 205, 41)";
export const supportiveHighlightFg = "rgb(0, 0, 0)";
export const supportiveHighlightSubtleFg = "rgb(0, 0, 0)";
export const supportiveHighlightDivider = "rgba(0, 0, 0, 0.5)";
export const supportiveHighlightOpaqueFg = "rgba(0, 0, 0, 0.15)";
export const supportiveFinanceBg = "rgb(255, 255, 255)";
export const supportiveFinanceFg = "rgb(41, 40, 39)";
export const supportiveFinanceSubtleFg = "rgb(71, 70, 70)";
export const supportiveOpinionFg = "rgb(255, 255, 255)";
export const supportiveOpinionSubtleFg = "rgb(255, 255, 255)";
export const supportiveOpinionBg = "rgb(30, 127, 110)";
export const supportiveNewsBg = "rgb(255, 255, 255)";
export const supportiveNewsFg = "rgb(41, 40, 39)";
export const supportiveNewsSubtleFg = "rgb(71, 70, 70)";
export const supportiveEconomyBg = "rgb(255, 255, 255)";
export const supportiveEconomyFg = "rgb(41, 40, 39)";
export const supportiveEconomySubtleFg = "rgb(71, 70, 70)";
export const supportiveLifestyleBg = "rgb(255, 255, 255)";
export const supportiveLifestyleFg = "rgb(41, 40, 39)";
export const supportiveLifestyleSubtleFg = "rgb(71, 70, 70)";
export const supportiveCommercialBg = "rgba(255, 255, 255, 0)";
export const supportiveCommercialFg = "rgb(0, 0, 0)";
export const supportiveCommercialAccent = "rgb(255, 245, 240)";
export const supportiveCommercialAccentBorder = "rgb(255, 235, 224)";
export const supportiveCommercialGradient = "linear-gradient(180deg, #fff5f0 0%, #ffffff00 100%)";
export const supportiveCultureBg = "rgb(255, 255, 255)";
export const supportiveCultureFg = "rgb(41, 40, 39)";
export const supportiveCultureSubtleFg = "rgb(71, 70, 70)";
export const supportiveNatureBg = "rgb(255, 255, 255)";
export const supportiveNatureFg = "rgb(41, 40, 39)";
export const supportiveNatureSubtleFg = "rgb(71, 70, 70)";
export const supportiveHealthBg = "rgb(255, 255, 255)";
export const supportiveHealthFg = "rgb(41, 40, 39)";
export const supportiveHealthSubtleFg = "rgb(71, 70, 70)";
export const supportiveCustomThreePulseStartBg = "rgba(255, 255, 255, 0.7)";
export const supportiveCustomThreePulseEndBg = "rgba(255, 255, 255, 0)";
export const supportiveCustomThreeBreakingBg = "rgb(220, 216, 216)";
export const supportiveCustomThreeDivider = "rgba(255, 255, 255, 0.3)";
export const supportiveCustomThreeBg = "rgb(220, 216, 216)";
export const supportiveCustomThreeFg = "rgb(41, 40, 39)";
export const supportiveCustomThreeSubtleFg = "rgb(41, 40, 39)";
export const supportiveCustomThreeBreakingFg = "rgb(255, 255, 255)";
export const supportiveUntoldBg = "rgb(255, 153, 255)";
export const supportiveUntoldFg = "rgb(0, 0, 0)";
export const teaserBg = "rgba(0, 0, 0, 0)";
export const teaserBubbleBg = "rgba(255, 255, 255, 0)";
export const teaserBubbleFg = "rgba(0, 0, 0, 0)";
export const iconsDiceBg = "rgb(233, 12, 12)";
export const teaserNoImageBg = "rgb(255, 255, 255)";
export const teaserBlackBreakingBg = "rgb(41, 40, 39)";
export const teaserBlackBreakingFg = "rgb(255, 255, 255)";
export const teaserBlackBreakingSubtleFg = "rgb(255, 255, 255)";
export const teaserFooterNoneBg = "rgb(255, 255, 255)";
export const teaserFooterBlackBg = "rgb(41, 40, 39)";
export const teaserFooterCustomOneBg = "rgb(0, 94, 115)";
export const teaserFooterCustomTwoBg = "rgb(254, 209, 252)";
export const teaserFooterHighlightBg = "rgb(255, 255, 255)";
export const accesibilityFocusOutline = "rgb(49, 111, 209)";
